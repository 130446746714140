@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Lobster+Two:wght@400;700&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import "sass/abstracts/variables";
@import "sass/components/mobileMenu";
@import "sass/layout/landing";
@import "sass/layout/sidebar";
@import "sass/layout/section";
@import "sass/layout/contact";


*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {

  font-size: 62.5%;
  font-family: 'Open Sans';
}

body {
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

.main {
  position: absolute;
  right: 0;
  width: 85vw;
  height: 100vh;
  overflow: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@media only screen and (max-width: 1000px) {
  .main {
      width: 100vw;
  }

}
