.landing {
    position: relative;
    top: 0;
    right: 0;
    width: 85vw;
    height: 80vh;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('../../images/Landing/gold-mac.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.landing-greeting {
    text-align: right;
    padding-top: 15vh;
    padding-bottom: 40px;
    padding-right: 150px;
}

.greeting {
    color: white;
    font-family: 'Open Sans';
    font-size: 80px;
}

.subtitle {
    color: white;
    font-family: 'Open Sans';
    font-size: 27px;
    font-weight: 500;
}

.landing-languages {
    text-align: right;
    padding-right: 110px;
    padding-bottom: 30px;
}

.language {
    color: white;
    font-family: 'IBM Plex Sans';
    background-color: rgba(255, 255, 255, 0.4);
    padding: 10px 20px;
    border-radius: 40px;
    font-weight: 600;
    margin-right: 25px;
    transition: .5s;
}

.language-logo {
    height: 40px;
    padding-right: 7px;
}

.shift {
    margin-right: 40px;
}


.html:hover {
    background-color: #E44D26;
}

.css:hover {
    background-color: #1672B6;
}
.js:hover {
    background-color: #D4B82F;
}

.node:hover {
    background-color: #9ada52;
}

.react:hover {
    background-color: #8de4fc;
}

.mongo:hover {
    background-color: #63bb5b;
}

.redux:hover {
    background-color: #8a58d6;
}

.sass:hover {
    background-color: #df6da8;
}

.bootstrap:hover {
    background-color: #563E7C;
}

.materialize:hover {
    background-color: #f09095;
}

#resume-img {
    display: block;
    width: 70%;
    margin: 90px auto;
    box-shadow: 0px 0px 20px 5px #ccc;
    transition: 0.4s;
}

#resume-img:hover {
    width: 73%;
}



@media only screen and (max-width: 1000px) {
    .landing {
        width: 100vw;
        height: 65vh;
        padding-right: 10vw;
        background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../../images/Landing/gold-mac.jpg');
        background-position: right;
    }

    .landing-greeting {
        text-align: right;
        padding-top: 19vh;
        padding-bottom: 40px;
        padding-right: 20px;
    }

    .greeting {
        font-size: 50px;
    }

    .subtitle {
        font-size: 18px;
    }

    .landing-languages {
        text-align: right;
        padding-right: 10px;
        padding-bottom: 20px;
    }
    
    .language {
        padding: 3px 10px;
        margin-right: 15px;
        font-size: 12px;
    }
    
    .language-logo {
        height: 20px;
        padding-right: 7px;
    }
    
    .shift {
        margin-right: 20px;
    }
    

    #resume-img {
        width: 90%;
        margin: 60px auto;
    }
}

@media only screen and (max-width: 500px) {
    .landing {
        padding-right: 0;
    }
}
