.col1  {
    text-align: right;
    padding-right: 40px;
    padding-top: 35px;
}

.col2 {
    padding-top: 35px;
    text-align: left;
    padding-left: 40px;
}

#letschat {
    font-family: "Quicksand";
    font-size: 45px;
    font-weight: 100;
    margin-bottom: 40px;
}

#contactmeat {
    font-size: 16px;
    color: white;
}

#contactmeatb {
    font-weight: 700;
    font-size: 16px;
    color: white;
}

#potrait {
    margin-top: 20px;
    width: 45%;
}

.footer {
    background-color: #222323;
    color: white;
}

.section.footer {
    padding-top: 0px;
    padding-bottom: 40px;
}

.copyright {
    background-color: #222323;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px #333131 solid;
}

#copyright {
    color: #494646;
    font-size: 14px;
    font-family: "IBM Plex Sans";
    font-weight: 500;
}


@media only screen and (max-width: 1000px) {
    .col1  {
        display: none;
    }
    
    .col2 {
        padding-left: 20px;
    }

    .footer {
        margin-top: 100px;
    }

}