.menu-wrap {
    display: none;
}

.toggler {
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 600;
    width: 70px;
    height: 70px;
    opacity: 0;
}

.hamburger {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 40px;
    height: 40px;
    padding: 1rem;
    margin: 2rem;
    background: rgba($color: gainsboro, $alpha: 0.5);
    display: flex; 
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.hamburger > div {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: white; 
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
}

.hamburger > div:before, 
.hamburger > div:after {
    content: '';
    position: absolute; 
    z-index: 9;
    top: -7px;
    width: 100%;
    height: 2px;
    background-color: white;
}

.hamburger > div:after {
    top: 7px;
}
 
.toggler:checked + .hamburger > div {
    transform: rotate(135deg);
}

.toggler:checked + .hamburger > div:before,
.toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
}

@media only screen and (max-width: 1000px) {
    .menu-wrap {
        display: block;
    }
}